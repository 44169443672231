import { Application } from "@hotwired/stimulus"
import { ConfirmVestingModalController } from "./confirm_vesting_modal_controller"
import { FileUploadController } from "./file_upload_controller"
import { InstantSearchController } from "./instant_search_controller"
import { PlaidLinkController } from "./plaid_link_controller"
import { PrintController } from "./print_controller"
import { VestingPlanPreviewController } from "./vesting_plan_preview_controller"

const application = Application.start()

application.debug = false
Stimulus = application

application.register("confirm-vesting-modal", ConfirmVestingModalController)
application.register("file-upload", FileUploadController)
application.register("instant-search", InstantSearchController)
application.register("plaid-link", PlaidLinkController)
application.register("print", PrintController)
application.register("vesting-plan-preview", VestingPlanPreviewController)

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
