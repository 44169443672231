import type { Plaid } from "plaid-link"

export interface VestingPlanPreview {
  options_granted: number
  first_vesting_date: string
  vesting_frequency: number[]
  vesting_proportion: number[]
}

export const createVestingPlanPreview = async (vesting_plan_preview: VestingPlanPreview) => {
  return post("/api/vesting_plan_previews", { vesting_plan_preview })
}

export const createPlaidItem = async (metaData: Plaid.OnSuccessMetaData) => {
  return post("/plaid_items", metaData)
}

export const createPlaidLinkToken = async () => {
  return post("/api/plaid_links", "")
}

const getCsrfToken = () => {
  return document.querySelector<HTMLMetaElement>("meta[name='csrf-token']")?.content
}

const post = (path, body) => {
  return fetch(path, {
    method: "POST",
    headers: {
      "X-CSRF-Token": getCsrfToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
}
