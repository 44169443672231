import { Controller } from "@hotwired/stimulus"
import { useRender, html } from "stimulus-lit"
import type { SlInput } from "@shoelace-style/shoelace"
import { createVestingPlanPreview, VestingPlanPreview } from "../apiClient"

interface VestingPlan {
  vesting_date: string
  quantity: number
}

export class VestingPlanPreviewController extends Controller<HTMLFormElement> {
  static targets = [
    "firstVestingDateInput",
    "planPreview",
    "quantityInput",
    "vestingFrequencyInput",
    "vestingProportionInput",
  ]

  static values = { vestingPlanPreview: [] }

  declare readonly firstVestingDateInputTarget: SlInput
  declare readonly planPreviewTarget: HTMLDivElement
  declare readonly quantityInputTarget: SlInput
  declare readonly vestingFrequencyInputTargets: SlInput[]
  declare readonly vestingProportionInputTargets: SlInput[]
  declare vestingPlanPreviewValue: VestingPlan[]

  connect() {
    useRender(this, { container: this.planPreviewTarget })
  }

  async updatePreview() {
    const planPreview: VestingPlanPreview = {
      options_granted: parseInt(this.quantityInputTarget.value),
      first_vesting_date: this.firstVestingDateInputTarget.value,
      vesting_frequency: this.vestingFrequencyInputTargets.map(({ value }) => parseInt(value)),
      vesting_proportion: this.vestingProportionInputTargets.map(({ value }) => parseInt(value) / 100),
    }

    if (Object.values(planPreview).every(Boolean)) {
      this.vestingPlanPreviewValue = await (await createVestingPlanPreview(planPreview)).json()
    }
  }

  addEvent() {
    this.vestingPlanPreviewValue = [...this.vestingPlanPreviewValue, { vesting_date: null, quantity: null }]
  }

  removeEvent(indexToRemove: number) {
    this.vestingPlanPreviewValue = this.vestingPlanPreviewValue.filter((_, index) => index !== indexToRemove)
  }

  updateDate(event: CustomEvent, indexToUpdate: number) {
    const vesting_date = (event.target as SlInput).value

    this.vestingPlanPreviewValue = this.vestingPlanPreviewValue.map((_, index) =>
      index !== indexToUpdate ? _ : { ..._, vesting_date }
    )
  }

  updateQuantity(event: CustomEvent, indexToUpdate: number) {
    const quantity = parseInt((event.target as SlInput).value)

    this.vestingPlanPreviewValue = this.vestingPlanPreviewValue.map((_, index) =>
      index !== indexToUpdate ? _ : { ..._, quantity }
    )
  }

  render() {
    if (this.vestingPlanPreviewValue.length > 0) {
      return html`
        <table class="divide-y divide-gray-300 text-sm text-gray-500">
          <thead>
            <tr class="text-left text-gray-900">
              <th class="whitespace-nowrap px-2 py-3"></th>
              <th class="whitespace-nowrap px-2 py-3">付与日</th>
              <th class="whitespace-nowrap pl-2 pr-8 py-3 text-end">株数</th>
              <th class="w-0 whitespace-nowrap px-2 py-3 text-end"></th>
            </tr>
          </thead>

          <tbody class="divide-y">
            ${this.vestingPlanPreviewValue.map(
              ({ vesting_date: vestingDate, quantity }, index) => html`
                <tr>
                  <td class="w-0 text-end px-2 py-2">
                    <sl-icon name="list" class="cursor-grab"></sl-icon>
                  </td>
                  <td class="whitespace-nowrap w-0 px-2 py-2">
                    <sl-input required filled name="rsu_plan[vesting_events][][date]" type="date" size="small" value=${vestingDate} @sl-change="${(event) => this.updateDate(event, index)}"></sl-inputfilled>
                  </td>
                  <td class="px-2 py-2">
                    <sl-input required filled name="rsu_plan[vesting_events][][quantity]" class="w-24 text-right" type="number" size="small" value=${quantity} @sl-change="${(event) => this.updateQuantity(event, index)}"></sl-input>
                  </td>
                  <td class="w-0 text-end px-2 py-2">
                    <sl-icon-button name="x-lg" label="削除" @click="${() => this.removeEvent(index)}"></sl-icon-button>
                  </td>
                </tr>
              `
            )}
          </tbody>

          <tfoot>
            <tr>
              <td></td>
              <td>
                <sl-button variant="text" size="small" @click="${() => this.addEvent()}">
                  <sl-icon slot="prefix" name="plus-lg"></sl-icon>
                  追加
                </sl-button>
              </td>
              <td class="text-end py-2">
                <div class="flex justify-between pl-3 pr-8">
                  <div>合計</div>
                  <div>${this.vestingPlanPreviewValue.reduce((total, { quantity }) => total + quantity, 0)}</div>
                </div>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      `
    }
  }
}
