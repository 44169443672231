import { Controller } from "@hotwired/stimulus"
import { useRender, html } from "stimulus-lit"

export class ConfirmVestingModalController extends Controller {
  static values = {
    shouldDisplayModal: Boolean,
    releaseConfirmationUrl: String,
  }

  declare shouldDisplayModalValue: boolean
  declare readonly releaseConfirmationUrlValue: string

  connect() {
    useRender(this)
  }

  open() {
    this.shouldDisplayModalValue = true
  }

  onRequestClose = (event) => {
    if (event.detail.source === "overlay" || event.detail.source === "keyboard") {
      event.preventDefault()
    } else {
      this.shouldDisplayModalValue = false
    }
  }

  closeModalAndReload() {
    this.shouldDisplayModalValue = false
    Turbo.visit(window.location.href, { action: "replace" })
  }

  render() {
    return html`
      <sl-dialog label="制限解除の確認" ?open=${this.shouldDisplayModalValue} @sl-request-close=${this.onRequestClose}>
        ${this.shouldDisplayModalValue
          ? html`<turbo-frame id="new-release-confirmation" src="${this.releaseConfirmationUrlValue}"></turbo-frame>`
          : null}
      </sl-dialog>
    `
  }
}
