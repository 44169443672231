import { Controller } from "@hotwired/stimulus"
import Dashboard from "@uppy/dashboard"
import Uppy from "@uppy/core"
import { useRender, html } from "stimulus-lit"
import { ActiveStorageUpload } from "packages/uppy-activestorage-upload"

export interface ActiveStorageResponse {
  body: {}
  signed_id: string
  status: number
  uploadURL: string
  preview: string
}

export class FileUploadController extends Controller<HTMLElement> {
  static values = {
    directUploadUrl: String,
    inputName: String,
    signedId: String,
  }

  declare directUploadUrlValue: string
  declare inputNameValue: string
  declare signedIdValue: string

  connect() {
    useRender(this)

    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: [".pdf"],
        maxNumberOfFiles: 1,
      },
    })

    uppy.use(Dashboard, { target: this.element, inline: true, showProgressDetails: true, height: "200px" })
    uppy.use(ActiveStorageUpload, { directUploadUrl: this.directUploadUrlValue })
    uppy.on("complete", ({ successful }) => {
      this.signedIdValue = (successful[0].response as ActiveStorageResponse).signed_id
    })
  }

  ignore(event: PointerEvent) {
    event.preventDefault()
  }

  render() {
    return this.signedIdValue
      ? html`<input type="hidden" name=${this.inputNameValue} value=${this.signedIdValue} />`
      : null
  }
}
