import { Controller } from "@hotwired/stimulus"
import { createPlaidItem, createPlaidLinkToken } from "apiClient"
import type { SlButton } from "@shoelace-style/shoelace"

export class PlaidLinkController extends Controller {
  static values = {
    token: String,
  }

  declare readonly tokenValue: string

  connect() {
    if (typeof window.Plaid === "undefined") {
      const script = document.createElement("script")
      script.src = `https://cdn.plaid.com/link/v2/stable/link-initialize.js`

      document.body.appendChild(script)
    }
  }

  async open(event: Event) {
    const button = event.target as unknown as SlButton

    try {
      button.disabled = button.loading = true
      const response = await createPlaidLinkToken()

      if (response.status === 200) {
        const { link_token: LinkToken } = await response.json()

        const handler = window.Plaid.create({
          token: LinkToken,
          onSuccess: async (publicToken, metaData) => {
            console.log("onSuccess", publicToken, metaData)

            await createPlaidItem(metaData)
            setTimeout(() => Turbo.visit("/financial_accounts"), 2000)
          },
          onExit: (error, metaData) => {
            console.log("onExit", error, metaData)
          },
          onEvent: (eventName, metaData) => {
            console.log("onEvent", eventName, metaData)
            // if (eventName === "ERROR") {
            //   Sentry.withScope((scope) => {
            //     scope.setExtra("plaidMetaData", metaData)
            //     Sentry.captureException(new Error("An error occurred on Plaid Link."))
            //   })
            // }
          },
        })

        handler.open()
      } else {
        this.notify("An error occurred while trying to connect to Plaid.", "warning", "exclamation-triangle")
      }
    } finally {
      button.disabled = button.loading = false
    }
  }

  notify(message, variant = "warning", icon = "info-circle", duration = 5000) {
    const alert = Object.assign(document.createElement("sl-alert"), {
      variant,
      duration,
      closable: true,
      innerHTML: `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${message}
      `,
    })

    document.body.append(alert)
    return alert.toast()
  }
}
